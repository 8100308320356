<template>
  <v-card elevation="2">
    <v-card-title>
      {{ $t("collection.advance_search.title") }}
    </v-card-title>
    <v-card-subtitle class="pb-6">
      {{ $t("collection.advance_search.description") }}
    </v-card-subtitle>
    <SearchFilter @query-filter="loadWorks" />
    <SelectedFilters :queryFilters="queryFilters" :localFilters="localFilters"/>
    <v-card-title> Opere {{ filteredWorks.length }} </v-card-title>
    <linear-loading v-if="isLoading" :isLoading="isLoading"></linear-loading>
    <works-list v-else-if="hasWorks" :works="filteredWorks"> </works-list>
    <v-container v-else id="works">
      <v-card>
        <v-card-title> Non ci sono opere </v-card-title>
      </v-card>
    </v-container>
    <v-dialog width="500" :show="!!error">
      <v-card>
        <v-card-title class="error white--text"> Error </v-card-title>
        <v-card-text>
          {{ error }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import WorksList from "@/components/collection/works/WorksList";
import axios from "axios";
import SearchFilter from "@/components/search/SearchFilter";
import SelectedFilters from '@/components/search/SelectedFilters'
import { mapGetters } from "vuex";

export default {
  components: {
    WorksList,
    SearchFilter,
    SelectedFilters
  },
  data() {
    return {
      error: null,
      isLoading: false,
      keywords: [],
    };
  },
  computed: {
    ...mapGetters(["localFilters", "queryFilters"]),
    doi(){
      let variabile = this.keywords.filter((keyword) => {
        if(this.localFilters.keywords.includes(keyword.LABEL)){
          return true;
        }
      })

      let array = [];

      variabile.forEach(keyword => {
        keyword.EXPRESSIONS.forEach(expression => {
          
          let part = expression.document.substring(
            expression.document.lastIndexOf("/data/") + 6, 
            expression.document.lastIndexOf("/v1")
          );

          if(!array.includes(part)){
            array.push(part);
          }
          
        })
      });
      return array;
    },
    filteredWorks() {
      const works = this.$store.getters["works/works"];
      return works.filter((work) => {
        if(this.doi.length === 0){
          works.forEach((work) => {
            this.doi.push(work.ident.replace(/_/g,''));
          })
        }

        if (work.title.toUpperCase().includes(this.localFilters.title.toUpperCase().trim()) && this.doi.includes(work.ident.replace(/_/g,''))){         
          return true;
        }
      });
    },
    hasWorks() {
      return !this.isLoading && this.filteredWorks.length > 0;
    }    
  },
  methods: {
    async loadWorks(filters) {
      this.isLoading = true;
      await this.$store.dispatch("works/loadWorks", filters);
      this.isLoading = false;
    },
    getKeywords(){
      axios.get("/api/indices/keywords").then((data) => {
        this.keywords = data.data;
      });
      
    },
  },
  async created() {
    this.loadWorks(this.queryFilters);
    this.getKeywords();
  },
};
</script>
