<template>
  <v-form>
    <v-container class="mb-10">
      <v-row>
        <v-col 
        class="mb-0 pb-0" 
        cols="12"
        md="4"
        >
          <v-text-field
            v-model="localFilters.title"
            :label="$t('collection.advance_search.searchTitle.title')"
            hint="Ricerca tra i titoli delle opere"
            persistent-hint
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col 
        class="mb-0 pb-0" 
        cols="12"       
        md="5"
        >
          <v-autocomplete
            v-model="localFilters.keywords"
            :items="keywordsLabels"
            outlined
            dense            
            deletable-chips
            clearable            
            chips
            menu-props="{transition: 'scroll-y-transition'}"
            hint="Ricerca le parole chiave tra le opere"
            persistent-hint
            small-chips
            :label="$t('collection.advance_search.keywords.title')"
            multiple
          >
          </v-autocomplete>
        </v-col>
        <v-col 
        class="m-0 p-1 d-flex" 
        cols="12"
        md="3"
        >
          <v-checkbox
          class="mr-7 p-0"
          v-model="queryFilters.docstatus" 
          value="published"
          >
            <template v-slot:label>
              {{ $t("collection.advance_search.docstatus.list.published") }}
            </template>
          </v-checkbox>
          <v-checkbox
            class="m-0 p-0"
            v-model="queryFilters.docstatus"
            value="unpublished"
          >
            <template v-slot:label>
              {{ $t("collection.advance_search.docstatus.list.unpublished") }}
            </template>
          </v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div class="text-center alig-center justify-center d-flex pt-0 mt-0">
            <div class="mr-2">
              <v-btn @click="all" x-small color="primary" width="100">
                Apri
              </v-btn>
            </div>
            <div class="ml-2">
              <v-btn @click="none" x-small color="primary" plain width="100">
                Chiudi
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-expansion-panels v-model="panel" multiple>
          <v-col class="mt-0 pt-0" cols="12" sm="6" md="6">
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t("collection.advance_search.structure.title") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="filter-content">
                <Structure
                  :filtersPath="queryFilters.path"
                  @changed-path="changePath"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-col>
          <template v-for="metadata in metadataFilters">
            <v-col
              :key="metadata.title"
              class="mt-0 pt-0"
              cols="12"
              sm="6"
              md="6"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t(metadata.title) }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="filter-content">
                  <v-data-table
                    show-select
                    v-model="queryFilters[metadata.query]"
                    :headers="headers"
                    :items="metadata.items"
                    :items-per-page="-1"
                    hide-default-footer
                    mobile-breakpoint="0"
                    dense
                  >
                    <template v-slot:[`header.data-table-select`]="{}">
                      <div></div>
                    </template>
                    <template v-slot:[`header.name`]="{}">
                      {{ $t(metadata.header) }}
                    </template>
                    <template
                      v-slot:[`item.data-table-select`]="{ isSelected, select }"
                    >
                      <v-simple-checkbox
                        color="primary"
                        :value="isSelected"
                        @input="select($event)"
                        :ripple="false"
                      ></v-simple-checkbox>
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                      <div>
                        {{ $t(item.name) }}
                      </div>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
          </template>
        </v-expansion-panels>
      </v-row>
      <v-row>
        <v-col>
          <RangeSlider
            @set-date="changeDate"
            :date="date"
            :selAllDate="selAllDate"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Structure from "@/components/search/filters/Structure";
import RangeSlider from "@/components/search/filters/RangeSlider";

export default {
  components: {
    RangeSlider,
    Structure,
  },
  emits: ["query-filter"],
  data() {
    return {
      panel: [],
      filterPanels: 4,
      headers: [
        {
          align: "start",
          value: "name",
        },
      ],
      date: [1930, 1978],
      selAllDate: true,
      min: 1930,
      max: 1978,

      keywords: [],
      keywordsLabels: [],
    };
  },
  computed: {
    ...mapGetters(["localFilters", "queryFilters"]),
    doctype() {
      let name = "doctype";
      const list = this.getList(name);

      return this.createMetaList(list, name);
    },
    doctopic() {
      let name = "doctopic";
      const list = this.getList(name);

      return this.createMetaList(list, name);
    },
    role() {
      let name = "role";
      const list = this.getList(name);

      return this.createMetaList(list, name);
    },
    metadataFilters() {
      return [
        {
          header: "collection.advance_search.doctype.header",
          title: "collection.advance_search.doctype.title",
          items: this.doctype,
          query: "doctypeList",
        },
        {
          header: "collection.advance_search.doctopic.header",
          title: "collection.advance_search.doctopic.title",
          items: this.doctopic,
          query: "doctopicList",
        },
        {
          header: "collection.advance_search.role.header",
          title: "collection.advance_search.role.title",
          items: this.role,
          query: "roleList",
        },
      ];
    },
  },
  methods: {
    all() {
      this.panel = [...Array(this.filterPanels).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panel = [];
    },
    changeDate(state) {
      this.queryFilters.date = state.date;
      this.queryFilters.selAllDate = state.selDate;
    },
    changePath(state) {
      this.queryFilters.path = state;
    },
    createMetaList(list, meta) {
      let metaArray = [];
      let obj = {};

      for (let x in list) {
        obj = {
          id: x,
          name: `collection.advance_search.${meta}.list.${x}`,
          //selected: false,
        };
        metaArray.push(obj);
      }

      return metaArray;
    },
    // get meta list from json
    getList(meta) {
      const json = require("/src/locales/it.json");
      const metaJSON = json.collection.advance_search[meta].list;

      return metaJSON;
    },
    getKeywords() {
      axios.get("/api/indices/keywords").then((data) => {
        this.keywords = data.data;
        this.keywords.forEach((keyword) => {
          this.keywordsLabels.push(keyword.LABEL);
          this.keywordsLabels.sort();
        });
      });
    },
    remove(item) {
      const index = this.selectedKeywords.indexOf(item);
      if (index >= 0) this.selectedKeywords.splice(index, 1);
    },
  },
  watch: {
    queryFilters: {
      handler(val) {
        this.$emit("query-filter", val);
      },
      deep: true,
    },
  },
  created() {
    //Set date
    this.date = this.queryFilters.date;
    this.selAllDate = this.queryFilters.selAllDate;

    this.getKeywords();
  },
};
</script>

<style lang="scss" scoped>

.sheet {
  overflow: auto;
  white-space: nowrap;
}

.disable-event {
  pointer-events: none;
  opacity: 0.5;
}

.filter-content {
  height: 150px;
  overflow-y: scroll;
}
</style>