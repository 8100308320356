<template>
  <div>
    <v-container v-if="works.length > 0 && slim" id="works">
      <!-- <v-lazy
        v-for="(work, i) in works" :key="i"        
        v-model="work.isActive"
        :options="{
          threshold: 0.3,
        }"
        min-height="100"
        transition="fade-transition"
      > -->
        <v-card v-for="(work, i) in works" :key="i" class="mt-3 pb-2">
          <div class="d-flex align-center pb-2">
            <v-card-title class="text-break">
              {{ work.title }}
            </v-card-title>
            <v-btn
              @click="link(work.ident)"
              class="ml-auto mr-4"
              color="primary"
              small
            >
              <!--:to="link(work.ident)"-->
              {{ $t('basics.goToWork') }}
            </v-btn>
          </div>
          <v-sheet class="mb-2 overflow" max-height="85">
            <v-card-text class="py-0">
              {{ work.abstract }}
            </v-card-text>
          </v-sheet>
        </v-card>
      <!-- </v-lazy> -->
    </v-container>
    <v-container v-else-if="works.length > 0" id="works">
      <work-card v-for="(w, i) in works" :key="i" :work="w"> </work-card>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["works", "slim"], 
  methods: {
    async link(ident) {
      console.log("PAGE", this.$route.path);
      await this.$store.dispatch("changeBrowisingState", this.$route.path);
      this.$router.push(`/edition/browse/work/${ident.replace(/_/g, "")}`);
    },
  },
};
</script>

<style lang="scss">
#works {
  background-color: #005a96;
}
.overflow {
  overflow: auto;
}
</style>
